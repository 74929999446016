/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import {
  Layout,
  PageBuilder,
  SEO,
  StructuredData,
  TubCarousel
} from "~components";
import FullPageCarousel from "~components/PageBuilder/sections/FullPageCarousel.jsx";
import FrostedCanvas from "~components/PageBuilder/sections/FrostedCanvas.jsx";
import CollectionBannerBox from "~components/PageBuilder/sections/CollectionBannerBox.jsx";
import CollectionBannerTub from "~components/PageBuilder/sections/CollectionBannerTub.jsx";

/** ============================================================================
 * @component
 * Core template for static site paths.
 */
const Page = ({ data, location }) => {
  const cms = data.sanityPage;

  const {
    pagebuilder: { sections }
  } = cms;

  const seoProps = {
    customTitle: cms?.seoTitle ? cms.seoTitle : cms.name,
    customDescription: cms?.seoDescription,
    customKeywords: cms?.seoKeywords,
    path: location.pathname
  };

  const threeColBannerSection = {
    sections: cms?.pagebuilder?.sections.filter(
      ({ _type }) => _type === `threeColBanner`
    )
  };

  const pageSections = {
    sections: cms?.pagebuilder?.sections.filter(
      ({ _type }) => _type !== `threeColBanner`
    )
  };

  return (
    <>
      <SEO {...seoProps} />
      <StructuredData sections={sections} {...seoProps} />

      <Layout>
        {threeColBannerSection?.sections?.[0] && (
          <PageBuilder pagebuilder={threeColBannerSection} />
        )}
        {/* {place comment here} */}
        {pageSections?.sections?.[0] && (
          <PageBuilder pagebuilder={pageSections} />
        )}
      </Layout>
    </>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    sanityPage(id: { eq: $id }) {
      name
      seoTitle
      seoDescription
      seoKeywords

      ...PageBuilderFragment
    }
  }
`;

// {location?.pathname === `/` && (
//     <>
//       <TubCarousel />
//       <CollectionBannerBox />
//       {/* <FullPageCarousel /> */}
//       {/* <FrostedCanvas /> */}
//       {/* <CollectionBannerTub /> */}
//     </>
//   )}
